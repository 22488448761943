import './modal.css'
import { type image } from '../../constants/img';
import { BiCollapseAlt } from "react-icons/bi";

const Modal = ({img, hideImg}: {img: image, hideImg: () => void}) => {
    return <div className="modal-container">
        <img src={img.path} alt={img.alt} className="modal-img"/>
        <button type="button" aria-label='Fermer le mode plein écran' className="modal-close-btn" onClick={() => hideImg()}>
            <BiCollapseAlt color={"#1a0802"} size={"100%"}></BiCollapseAlt>
        </button>
    </div>
}

export default Modal