import './header.css';
import { IoLogoInstagram } from "react-icons/io";
import img from "../../assets/img/header.jpg";

function Header() {
  return (
    <section className="header">
        <div className="instagram-container">
            <a className="instagram" title="Visitez ma page Instagram" href="https://www.instagram.com/laureflesch/?hl=fr" target="_blank" rel="noopener noreferrer">
                <IoLogoInstagram color="#F1875E" size="3rem"></IoLogoInstagram>
            </a>
        </div>
        <div className="title-container">
            <h1 className="name">Laure Flesch</h1>
            <h2 className="subtitle-txt">Décoratrice d'intérieur</h2>
        </div>
        <img className="header-bg" src={img} alt="Banquettes Régent Petite France Strasbourg"></img>
    </section>
  );
}

export default Header;
