import texture from "../../assets/svg/texture.svg";
import './infos.css';

function Infos() {
  return (
    <section className="infos">
		<img src={texture} alt="background texture" className="texture"></img>
        <div className="infos-container">
            <div className="row">
				<div className="top-detail"></div>
              	<p className="desc">
					Parce que « le bouche à oreilles » est le meilleur des médias, 
					la recommandation la plus fiable, celle qu'on ne remet pas en question, 
					j'aime l'idée de rester discrète sur les réseaux habituels et de laisser 
					mon numéro se chuchoter, comme une confidence...&lrm;
                </p>
            </div>
            <div className="row">
              	<span className="tel">0782.233.433</span>
				<div className="bottom-detail"></div>
            </div>
        </div>
    </section>
  );
}

export default Infos;
