import './App.css';
import Carousel from './lib/components/gallery/carousel';
import Header from './lib/components/header/header';
import Infos from './lib/components/infos/infos';
import { useEffect, useState } from 'react';

function App() {
  const [isMobile, setIsMobile] = useState(false);
  
  /**
   * Checks if the current window size is a mobile size.
   * @returns true if the window size is less or equal to 768.
   */
  const isWindowWidthMobile = () => {
    if (!window) return (false);
    return (window.innerWidth <= 768);
  }

  useEffect(() => {
    /**
     * update isMobile state.
     */
    const detectDevice = () => {
      setIsMobile(isWindowWidthMobile()); 
    };

    detectDevice();
    window.addEventListener('resize', detectDevice);

    return () => {
      window.removeEventListener('resize', detectDevice);
    };
  }, []);

  return (
    <div className="App">
      <Header></Header>
      {!isMobile && <Carousel isMobile={isMobile}></Carousel>}
      <Infos></Infos>
      {isMobile && <Carousel isMobile={isMobile}></Carousel>}
    </div>
  );
}

export default App;

