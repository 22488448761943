import { useSwiper } from 'swiper/react';
import {BiLeftArrowAlt, BiRightArrowAlt} from 'react-icons/bi';

export function SlideNextButton() {
  const swiper = useSwiper();

  return (
        <button type="button" aria-label='Prochaine image' className="swiper-button-next column-center" onClick={() => swiper.slideNext()}>
		    <BiRightArrowAlt color={"#F1875E"} size={"40px"}></BiRightArrowAlt>
	    </button>
  );
}

export function SlidePrevButton() {
    const swiper = useSwiper();
  
    return (
        <button type="button" aria-label='Image précédente' className="swiper-button-prev column-center" onClick={() => swiper.slidePrev()}>
            <BiLeftArrowAlt color={"#F1875E"} size={"40px"}></BiLeftArrowAlt>
        </button>
    );
}