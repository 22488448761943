import './carousel.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Lazy } from 'swiper';
import { SlideNextButton, SlidePrevButton } from './navigation';
import svg from '../../assets/svg/detail.svg';
import 'swiper/css';
import 'swiper/css/lazy';

import { images, getSizedUrl, type image } from '../../constants/img';
import Modal from './modal';
import { useEffect, useState } from 'react';

/**
 * Checks if the image is a portrait or a landscape.
 * @param image - The HTML element of the image to check.
 * @returns true if the height of the image is greater than its width, otherwise false.
 */
function isHeightGreaterThanWidth(image: image): boolean {
	return image.height > image.width;
}

/**
 * Adds or updates the 'asset' parameter in the URL search parameters with the provided ID.
 * @param {string} id - The ID to be added or updated in the URL search parameters.
 */
const addAssetSearchParams = (id:string) => {
	const params = new URLSearchParams(window.location.search);
	params.set('asset', id);
	const newParams = params.toString();
	window.history.replaceState({}, '', `${window.location.pathname}?${newParams}`);
};

/**
 * Deletes any existing path parameters from the URL.
 */
const deletePath = () => {
	window.history.replaceState({}, '', `${window.location.pathname}`);
}

/**
 * Retrieves the value of the 'asset' parameter from the URL search parameters.
 * @returns {string|null} The value of the 'asset' parameter if present, otherwise null.
 */
const getAssetSearchParams = () => {
	const params = new URLSearchParams(window.location.search);
	return params.get('asset');
};

const Carousel = ({ isMobile }: { isMobile: boolean }) => {
	const [zoomImg, setZoomImg_] = useState<image | null>(null);

	/**
	 * Calculates the appropriate width for an image within the carousel based on its aspect ratio and the device type 
	 * @param image - The HTMLImageElement for which to determine the width.
	 * @returns The percentage width value to be applied to the image element.
	*/
	const getImgHeight = (image: image) => {
		if (isMobile) return isHeightGreaterThanWidth(image) ? '100%' : '80%'
		return isHeightGreaterThanWidth(image) ? '70%' : '40%'
	}
	
	/**
	 * Sets the zoom image and updates the URL search parameters accordingly.
	 * If the provided image object is not null and contains a valid path, it adds the image name to the URL search parameters.
	 * If the image object is null or does not contain a valid path, it removes any existing path parameters from the URL.
	 * Finally, it sets the zoom image using the provided image object.
	 * @param {Object|null} img - The image object to be set as the zoom image.
	*/
	const setZoomImg = (img:image|null) => {
		if (img && img.path) addAssetSearchParams(img.name)
		else deletePath();
		setZoomImg_(img);
	}

	/**
	 * hidding zoom image
	*/
	const hideZoomImg = () => {
		setZoomImg(null);
	}

	useEffect(() => {
		/**
		 * Retrieves an image object from the 'images' array based on the provided name.
		 * @param {string} name - The name of the image to retrieve.
		 * @returns {image|null} The image object if found, otherwise null.
		 */
		const getImgFromName = (name:string): image|null => {
			for (let i = 0; i < images.length; i++)
				if (images[i].name === name) return images[i];
			return null;
		}

		/**
		 * Displays an image corresponding to the provided name by setting it as the zoom image.
		 * @param {string} name - The name of the image to be displayed.
		 */
		const showImgFromName = (name:string) => {
			const img = getImgFromName(name);
			setZoomImg(img);
		}

		const name = getAssetSearchParams();
		if (!name) return;
		showImgFromName(name);
	}, [])

	if (images.length)
		return (
			<section>
				<div className="carousel-header">
				<div className="recent-work">
					<h2>Galerie</h2>
					<img className="detail" alt="illustration detail" src={svg}></img>
				</div>
				</div>
				<div className="carousel-container">
					<Swiper
						modules={[Lazy]}
						className='swiper'
						direction="horizontal"
						spaceBetween={10}
						slidesPerView={isMobile ? 1 : 3}
						centeredSlides={true}
						loop={true}
						lazy={true}
						zoom={true}
					>
						{
							images.map((image) => (
								<SwiperSlide key={image.name}> 
									{({ isActive }) => (
										<button 
											className="carousel-image-btn" 
											type="button" 
											aria-label='Ouvrir en plein écran'
											style={{"height": getImgHeight(image)}} 
											onClick={() => setZoomImg(image)}
										>
											<img 
												className="carousel-image" 
												alt={image.alt}
												src={getSizedUrl(image, (isMobile ? 500 : 800), 60)} 
												style={{opacity: isActive ? '1' : '0.2'}}
												loading='lazy'
											/>
										</button>
									)}
								</SwiperSlide>
							))
						}
						<SlideNextButton></SlideNextButton>
						<SlidePrevButton></SlidePrevButton>
					</Swiper>
				</div>
				{zoomImg && <Modal img={zoomImg} hideImg={hideZoomImg}></Modal>}
		  	</section>
		);

	return <div className="void column-center">
		 <span className="void-txt">Désolé, la galerie n'est pas accessible pour le moment.</span>
		 {/* <Loader></Loader> */}
	</div>
}

export default Carousel;
