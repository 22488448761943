export interface image {
    name: string,
    height: number,
    width: number,
    alt: string,
    path?: string,
}

const IMGIX = "https://fleschsoftware-929842149.imgix.net/";

/**
 * Constructs a new URL for an image with the desired dimensions and quality.
 * @param img - The image object containing the source URL.
 * @param size - The desired height of the image.
 * @param q - The desired quality of the image.
 * @returns A URL string pointing to the same image but with the specified dimensions and quality.
*/
export const getSizedUrl = (img: image, size:number, q:number) => {
    return IMGIX + img.name + `?h=${size}&auto=format&q=${q}`
}

export const images: image[] = [
    {
        "name": "01.jpg",
        "height": 1996,
        "width": 1328,
        "alt": "Fauteuil Régent Petite France Strasbourg"
    },
    {
        "name": "010.jpg",
        "height": 1443,
        "width": 1949,
        "alt": "Salle à manger"
    },
    {
        "name": "011.jpg",
        "height": 1328,
        "width": 1996,
        "alt": "Salon de séjour Régent Petite France Strasbourg"
    },
    {
        "name": "carousel-3.jpg",
        "height": 1366,
        "width": 2048,
        "alt": "Agence d'immobilier Espaces et Commerces"
    },
    {
        "name": "carousel-5.jpg",
        "height": 1024,
        "width": 1584,
        "alt": "Salon de séjour"
    },
    {
        "name": "02.jpg",
        "height": 1990,
        "width": 1324,
        "alt": "Tables Petit déjeuner Régent Petite France Strasbourg"
    },
    {
        "name": "03.jpg",
        "height": 1245,
        "width": 1871,
        "alt": "Banquettes Petit déjeuner Régent Petite France Strasbourg"
    },
    {
        "name": "04.jpg",
        "height": 1274,
        "width": 1915,
        "alt": "Banquettes Petit déjeuner Régent Petite France Strasbourg"
    },
    {
        "name": "05.jpg",
        "height": 1324,
        "width": 1990,
        "alt": "Banquettes Régent Petite France Strasbourg"
    },
    {
        "name": "06.jpg",
        "height": 1320,
        "width": 1984,
        "alt": "Banquettes Régent Petite France Strasbourg"
    },
    {
        "name": "07.jpg",
        "height": 1323,
        "width": 1988,
        "alt": "Salle à manger"
    },
    {
        "name": "08.jpg",
        "height": 1244,
        "width": 1869,
        "alt": "Bar Régent Petite France Strasbourg"
    },
    {
        "name": "10.jpg",
        "height": 1270,
        "width": 1182,
        "alt": "Restaurant Il Felice Strasbourg"
    },
    {
        "name": "11.jpeg",
        "height": 918,
        "width": 876,
        "alt": "Textures murales"
    },
    {
        "name": "12.jpeg",
        "height": 1226,
        "width": 1636,
        "alt": "Salle de bains, sauna"
    },
    {
        "name": "13.jpeg",
        "height": 1202,
        "width": 1616,
        "alt": "Textures murales"
    },
    {
        "name": "14.jpeg",
        "height": 1254,
        "width": 1258,
        "alt": "Cuisine Le Koï au couvent Toulon"
    },
    {
        "name": "15.jpeg",
        "height": 1054,
        "width": 1258,
        "alt": "Intérieur Le Koï au couvent Toulon"
    },
    {
        "name": "16.jpeg",
        "height": 1258,
        "width": 1882,
        "alt": "Textures murales Encore Haguenau"
    },
    {
        "name": "171.jpeg",
        "height": 1080,
        "width": 1620,
        "alt": "Restaurant L'Eden Kaltenhouse"
    },
    {
        "name": "18.jpeg",
        "height": 986,
        "width": 2358,
        "alt": "Salle de restauration"
    },
    {
        "name": "19.jpeg",
        "height": 1166,
        "width": 2452,
        "alt": "Salle de restauration"
    },
    {
        "name": "20.jpeg",
        "height": 1252,
        "width": 832,
        "alt": "Suspensions murales"
    },
    {
        "name": "26.jpeg",
        "height": 1036,
        "width": 1562,
        "alt": "Fumoir Encore Haguenau"
    },
    {
        "name": "27.jpeg",
        "height": 1036,
        "width": 1570,
        "alt": "Bar Encore Haguenau"
    },
    {
        "name": "29.jpg",
        "height": 2016,
        "width": 1512,
        "alt": "Fauteuil"
    },
    {
        "name": "3.jpg",
        "height": 1594,
        "width": 2388,
        "alt": "Toilettes"
    },
    {
        "name": "31.jpg",
        "height": 2016,
        "width": 1512,
        "alt": "Textures murales"
    },
    {
        "name": "33.jpg",
        "height": 2444,
        "width": 1617,
        "alt": "Suspensions murales Les Semailles La Wantzenau"
    },
    {
        "name": "36.jpeg",
        "height": 1590,
        "width": 1194,
        "alt": "Décor Mural le Lio Haguenau"
    },
    {
        "name": "38.jpg",
        "height": 2016,
        "width": 1512,
        "alt": "Suspension Mural le Lio Haguenau"
    },
    {
        "name": "5.jpg",
        "height": 1197,
        "width": 1797,
        "alt": "Suspensions murales"
    },
    {
        "name": "6.jpg",
        "height": 1199,
        "width": 1793,
        "alt": "Textures murales"
    },
    {
        "name": "7.jpg",
        "height": 1197,
        "width": 1794,
        "alt": "Tiroirs"
    },
    {
        "name": "8.jpg",
        "height": 1197,
        "width": 1797,
        "alt": "Douche"
    },
    {
        "name": "carousel-2.jpg",
        "height": 1560,
        "width": 1147,
        "alt": "Maison Rose et Rouge Fleuriste Strasbourg"
    },
    {
        "name": "9.jpg",
        "height": 1196,
        "width": 1794,
        "alt": "Salle de bains"
    },
].map((img: image) => {return {...img, path:`/carousel/${img.name}`}})

export default {
    images: images,
    getSizedUrl: getSizedUrl
} as const;
